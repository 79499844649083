import { useEffect, useRef, useState } from "react";
import Caption from "./caption";
import NotSelectedView from "./not-selected";
import {
  CaptionsWrapper,
  CollapseButton,
  CollapseWrapper,
  ComparisonColumn,
  ComparisonRow,
  ComparisonWrapper,
} from "./styled";
import Video from "./video";
import { useSelector } from "react-redux";
import { IState } from "../../../../../types";
import { getIcon } from "../../../../../utils/get-icon";
import { compareCaptionsIgnoringKey } from "../helpers";
import { defaultApperance } from "../utils";

interface ComparisonProps {
  videoUrl: string;
  original: any;
}

const Comparison = ({ videoUrl, original }: ComparisonProps) => {
  const [playerHeight, setPlayerHeight] = useState({
    original: 0,
    comparison: 0,
  });
  const [videoCollapsed, setVideoCollapsed] = useState({
    original: false,
    comparison: false,
  });
  const originalVideoRef = useRef<HTMLVideoElement>(null);
  const comparisonVideoRef = useRef<HTMLVideoElement>(null);

  const originalCaptionsRef = useRef<HTMLDivElement>(null);
  const comparisonCaptionsRef = useRef<HTMLDivElement>(null);

  const isSyncing = useRef(false);

  const [originalDisplayText, setOriginalDisplayText] = useState("");
  const [comparisonDisplayText, setComparisonDisplayText] = useState("");

  const comparison = useSelector(
    (state: IState) => state.transcript.comparison
  );
  const compareLoading = useSelector(
    (state: IState) => state.transcript.compareLoading
  );

  const clickCollapse = (variant: "original" | "comparison") => {
    setVideoCollapsed({
      ...videoCollapsed,
      [variant]: !videoCollapsed[variant],
    });
  };

  useEffect(() => {
    const updatePlayerHeight = () => {
      if (originalVideoRef.current && comparisonVideoRef.current) {
        const originalVideoElement = originalVideoRef.current;
        const comparisonVideoElement = comparisonVideoRef.current;

        setPlayerHeight({
          original: originalVideoElement.clientHeight,
          comparison: comparisonVideoElement.clientHeight,
        });
      }
    };

    const originalVideoElement = originalVideoRef.current;
    const comparisonVideoElement = comparisonVideoRef.current;

    if (originalVideoElement && comparisonVideoElement) {
      originalVideoElement.addEventListener(
        "loadedmetadata",
        updatePlayerHeight
      );
      comparisonVideoElement.addEventListener(
        "loadedmetadata",
        updatePlayerHeight
      );
      window.addEventListener("resize", updatePlayerHeight);
    }

    return () => {
      if (originalVideoElement && comparisonVideoElement) {
        originalVideoElement.removeEventListener(
          "loadedmetadata",
          updatePlayerHeight
        );
        comparisonVideoElement.removeEventListener(
          "loadedmetadata",
          updatePlayerHeight
        );
        window.removeEventListener("resize", updatePlayerHeight);
      }
    };
  }, [originalVideoRef, comparisonVideoRef]);

  useEffect(() => {
    const originalStart = original?.captions[0]?.end;
    const comparisonStart = comparison?.content[0]?.end;

    if (originalVideoRef.current) {
      if (comparisonVideoRef.current?.currentTime !== originalStart) {
        originalVideoRef.current.currentTime = originalStart;
        setOriginalDisplayText(original?.captions[0]?.text);
      }
    }

    if (comparisonVideoRef.current) {
      if (comparisonVideoRef.current?.currentTime !== comparisonStart) {
        comparisonVideoRef.current.currentTime = comparisonStart;
        setComparisonDisplayText(comparison?.content[0]?.text);
      }
    }
  }, [comparison?.content, original?.captions]);

  const handleScroll = (sourceRef: any, targetRef: any) => {
    if (!isSyncing.current && sourceRef.current && targetRef.current) {
      isSyncing.current = true;
      targetRef.current.scrollTop = sourceRef.current.scrollTop;
    }
    isSyncing.current = false;
  };

  useEffect(() => {
    const originalCaptions = originalCaptionsRef.current;
    const comparisonCaptions = comparisonCaptionsRef.current;

    const onOriginalScroll = () =>
      handleScroll(originalCaptionsRef, comparisonCaptionsRef);
    const onComparisonScroll = () =>
      handleScroll(comparisonCaptionsRef, originalCaptionsRef);

    // Short delay to ensure all elements are fully loaded before attaching events
    const timeout = setTimeout(() => {
      if (originalCaptions && comparisonCaptions) {
        originalCaptions.addEventListener("scroll", onOriginalScroll);
        comparisonCaptions.addEventListener("scroll", onComparisonScroll);
      }
    }, 100);

    // Cleanup
    return () => {
      clearTimeout(timeout);
      if (originalCaptions && comparisonCaptions) {
        originalCaptions.removeEventListener("scroll", onOriginalScroll);
        comparisonCaptions.removeEventListener("scroll", onComparisonScroll);
      }
    };
  }, [playerHeight, videoCollapsed, original, comparison]);

  const differences = compareCaptionsIgnoringKey(
    original.captions,
    comparison?.content
  );

  return (
    <ComparisonWrapper>
      <ComparisonRow>
        <ComparisonColumn>
          <Video
            displayText={originalDisplayText}
            videoUrl={videoUrl}
            videoRef={originalVideoRef}
            configuration={original?.appearance}
            variant="original"
            collapsed={videoCollapsed.original}
          />
          <CollapseWrapper>
            <CollapseButton
              collapsed={videoCollapsed.original}
              onClick={() => clickCollapse("original")}
            >
              {getIcon("arrow-up")}
            </CollapseButton>
          </CollapseWrapper>
          <CaptionsWrapper
            playerHeight={playerHeight.original}
            isVideoCollapsed={videoCollapsed.original}
            ref={originalCaptionsRef}
          >
            {original?.captions?.map((caption: any) => {
              let difference = differences.find(
                (diff: any) => diff.key === caption.key
              );
              return (
                <Caption
                  caption={caption}
                  difference={difference}
                  isComparison={false}
                />
              );
            })}
          </CaptionsWrapper>
        </ComparisonColumn>
        <ComparisonColumn>
          {comparison && !compareLoading ? (
            <>
              <Video
                displayText={comparisonDisplayText}
                videoUrl={videoUrl}
                videoRef={comparisonVideoRef}
                configuration={comparison?.appearance ?? defaultApperance}
                variant="comparison"
                collapsed={videoCollapsed.comparison}
              />
              <CollapseWrapper>
                <CollapseButton
                  collapsed={videoCollapsed.comparison}
                  onClick={() => clickCollapse("comparison")}
                >
                  {getIcon("arrow-up")}
                </CollapseButton>
              </CollapseWrapper>
              <CaptionsWrapper
                playerHeight={playerHeight.comparison}
                isVideoCollapsed={videoCollapsed.comparison}
                ref={comparisonCaptionsRef}
              >
                {comparison?.content.map((caption: any) => {
                  let difference = differences.find(
                    (diff: any) => diff.key === caption.key
                  );
                  return (
                    <Caption
                      caption={caption}
                      difference={difference}
                      isComparison={true} // "true" because this is the comparison version
                    />
                  );
                })}
              </CaptionsWrapper>
            </>
          ) : (
            <NotSelectedView loading={compareLoading} />
          )}
        </ComparisonColumn>
      </ComparisonRow>
    </ComparisonWrapper>
  );
};

export default Comparison;
