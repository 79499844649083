import styled from "@emotion/styled";

export const Dashboard = styled.main`
  padding-top: 70px;
  @media (max-width: 975px) {
    padding-top: 25px;
    &.hide-navigation {
      padding-top: 0px;
    }
  }
`;
