import styled from "@emotion/styled";

export const RevisionsWrapper = styled.div`
  overflow-x: hidden;
  padding: 0px 10px;
  overflow-y: visible;
  margin-top: 10px;
  max-height: calc(100vh - 89.94px - 95px - 80px - 70px);
  padding-bottom: 40px;
  @media (max-width: 975px) {
    padding-top: 30px;
    padding-bottom: 0px;
  }
`;

export const ListWrapper = styled.div`
  padding: 0px 0px;
`;

export const SingleRevisionWrapper = styled.div<{
  isActive: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px;
  width: 100%;
  padding-left: ${({ isActive }) => (isActive ? "25px" : "17.5px")};
  transition: 0.5s;
  &:hover {
    padding-left: 25px;
    cursor: pointer;
    .revision-title {
      color: #3b3b3b;
    }
  }
`;

export const LeftBorder = styled.div<{
  isFirst: boolean;
  isLast: boolean;
}>`
  width: 3px;
  background-color: #f2f2f2;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  border-radius: 3px;
  ${({ isFirst }) => (isFirst ? `top: 50%` : ``)}
  ${({ isLast }) => (isLast ? `bottom: 50%` : ``)}
`;

export const LeftCircle = styled.div<{ isActive: boolean }>`
  position: absolute;
  width: 17.5px;
  height: 17.5px;
  border-radius: 100%;
  background-color: ${({ isActive }) => (isActive ? "#2f8de4" : "#f2f2f2")};
  top: translateY(-50%);
  left: -7px;
  border: 2px solid #fff;
`;

export const DetailsWrapper = styled.div`
  padding: 15px 5px;
`;

export const DetailsTitle = styled.span<{ active: boolean }>`
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  display: block;
  color: ${({ active }) => (active ? "#3b3b3b" : "grey")};
  font-size: 14px;
  padding: 0px !important;
  margin: 0px !important;
`;

export const DetailsDescription = styled.span`
  font-family: Poppins, sans-serif;
  color: grey;
  font-size: 14px;
`;

export const DetailsAuthor = styled.span`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #2f8de4 !important;
`;

export const DetailsText = styled.span`
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: grey;
  font-weight: 500;
`;

export const ButtonsWrapper = styled.div`
  text-align: right;
`;

export const Badge = styled.span`
  background-color: yellow;
  font-size: 0.75rem;
  padding: 0.4688rem 0.625rem;
  border-radius: 0.5rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  background-color: rgb(241, 250, 255);
  color: rgb(0, 158, 247);
  margin-right: 15px;
`;

export const EmptyWrapper = styled.div`
  text-align: center;
  padding: 10px 10px;
  margin-top: 25px;
`;

export const EmptyTextWrapper = styled.div`
  max-width: 350px;
  margin: 0 auto;
`;
