import { getIcon } from "../../../../../../utils/get-icon";
import {
  BindingIcon,
  CaptionWrapper,
  Text,
  TimeDisplay,
  UpperContainer,
  AddedText,
  RemovedText,
} from "./styled";
import { formatTime } from "../../helpers";
import DiffMatchPatch from "diff-match-patch";

interface CaptionProps {
  caption: any;
  difference: any;
  isComparison: boolean;
}

const Caption = ({ caption, difference, isComparison }: CaptionProps) => {
  const renderOriginalText = (before: string, after: string) => {
    const dmp = new DiffMatchPatch();
    const diff = dmp.diff_main(before, after);
    dmp.diff_cleanupSemantic(diff);

    return diff.map((part, index) => {
      const [operation, text] = part;

      // Show deletions in the original view
      if (operation === DiffMatchPatch.DIFF_DELETE) {
        return <RemovedText key={index}>{text}</RemovedText>;
      }

      // Unchanged text
      if (operation === DiffMatchPatch.DIFF_EQUAL) {
        return <span key={index}>{text}</span>;
      }

      // Skip insertions in the original view
      return null;
    });
  };

  const renderComparisonText = (before: string, after: string) => {
    const dmp = new DiffMatchPatch();
    const diff = dmp.diff_main(before, after);
    dmp.diff_cleanupSemantic(diff);

    return diff.map((part, index) => {
      const [operation, text] = part;

      // Show insertions in the comparison view
      if (operation === DiffMatchPatch.DIFF_INSERT) {
        return <AddedText key={index}>{text}</AddedText>;
      }

      // Unchanged text
      if (operation === DiffMatchPatch.DIFF_EQUAL) {
        return <span key={index}>{text}</span>;
      }

      // Skip deletions in the comparison view
      return null;
    });
  };

  const diffText = (before: string, after: string, isComparison: boolean) => {
    return isComparison
      ? renderComparisonText(before, after)
      : renderOriginalText(before, after);
  };

  return (
    <CaptionWrapper>
      <UpperContainer>
        <TimeDisplay>{formatTime(caption?.start)}</TimeDisplay>
        <BindingIcon>{getIcon("arrow-right")}</BindingIcon>
        <TimeDisplay>{formatTime(caption?.end)}</TimeDisplay>
      </UpperContainer>
      <Text>
        {difference
          ? diffText(
              difference.diff.text.before,
              difference.diff.text.after,
              isComparison
            )
          : caption?.text}
      </Text>
    </CaptionWrapper>
  );
};

export default Caption;
