import { useEffect } from "react";
import Button from "../../../../../library/button";
import {
  DetailsTitle,
  ButtonsWrapper,
  DetailsWrapper,
  DetailsDescription,
  RevisionsWrapper,
  SingleRevisionWrapper,
  ListWrapper,
  Badge,
  LeftBorder,
  LeftCircle,
  EmptyWrapper,
  EmptyTextWrapper,
} from "./styled";
import useRevision from "../../../../../hooks/useRevision";
import { sendErrorNotification } from "../../../../../library/notification";
import moment from "moment";
import { Text } from "../../../../../library/text";
import { Title } from "../../../../../library/title";
import { setActiveComparison } from "../../../../../redux/slices/transcript";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";

type Props = {
  revisions: any[];
  setIsRevisioning: any;
  isActive?: boolean;
};

const Index = ({ revisions, setIsRevisioning, isActive = false }: Props) => {
  const dispatch = useAppDispatch();
  const { getRevision } = useRevision();
  const latestRevision = useAppSelector(
    (state) => state.transcript?.data?.revisionRef
  );
  const comparison = useAppSelector((state) => state.transcript?.comparison);

  useEffect(() => {
    if (isActive) {
      setIsRevisioning(true);
    }
    return () => {
      setIsRevisioning(false);
      dispatch(setActiveComparison(null));
    };
  }, [dispatch, isActive, setIsRevisioning]);

  const clickSelectRevision = async (revisionId: string | null) => {
    try {
      await getRevision(revisionId);
    } catch (e) {
      sendErrorNotification("Kunne ikke hente revisjon.");
    }
  };

  const renderRevisions = () => {
    if (!revisions || revisions?.length === 1) {
      return (
        <EmptyWrapper>
          <Title size="sm">Ingen revisjoner</Title>
          <EmptyTextWrapper>
            <Text size="small" color="dark">
              Du må gjøre en endring i transkriptet ditt og lagre det for å se
              de ulike revisjonene.
            </Text>
          </EmptyTextWrapper>
        </EmptyWrapper>
      );
    }
    return (
      <ListWrapper>
        {revisions
          ?.filter((r) => r.id !== latestRevision)
          .map((revision, index, arr) => (
            <SingleRevisionWrapper isActive={comparison?.id === revision.id}>
              <LeftBorder
                isFirst={index === 0}
                isLast={index === arr.length - 1}
              />
              <LeftCircle isActive={comparison?.id === revision?.id} />
              <DetailsWrapper>
                <DetailsTitle
                  className="revision-title"
                  active={comparison?.id === revision?.id}
                >
                  {revision?.id
                    ? "Revisjon ble lagret"
                    : "Transkriptet ble opprettet"}
                </DetailsTitle>
                <DetailsDescription>
                  Den {moment.unix(revision?.timestamp).format("DD/MM/YYYY")}{" "}
                  kl. {moment.unix(revision?.timestamp).format("HH:mm")}
                </DetailsDescription>
              </DetailsWrapper>
              <ButtonsWrapper>
                {comparison?.id === revision?.id && <Badge>Aktiv</Badge>}
                <Button
                  size="sm"
                  variant="regular"
                  color="grey"
                  disabled={comparison?.id === revision?.id}
                  onClick={() => clickSelectRevision(revision?.id)}
                >
                  Velg
                </Button>
              </ButtonsWrapper>
            </SingleRevisionWrapper>
          ))}
      </ListWrapper>
    );
  };

  return <RevisionsWrapper>{renderRevisions()}</RevisionsWrapper>;
};

export default Index;
