import styled from "@emotion/styled";

export const ComparisonWrapper = styled.div`
  width: 100%;
  height: auto;
  z-index: 1;
  display: block;
`;

export const ComparisonRow = styled.div`
  display: flex;
  gap: 25px;
  justify-content: space-between;
  width: 100%;
`;

export const ComparisonColumn = styled.div`
  display: block;
  width: 100%;
`;

export const CaptionsWrapper = styled.div<{
  playerHeight: number;
  isVideoCollapsed: boolean;
}>`
  display: block;
  max-height: ${({ isVideoCollapsed, playerHeight }) =>
    isVideoCollapsed
      ? "calc(100vh - 89.94px - 95px - 80px - 70px)"
      : `calc(100vh - 89.94px - 95px - 80px - 70px - 218px - ${playerHeight}px);`};
  overflow-y: scroll;
`;

export const CollapseWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 15px;
  border-bottom: 0.125rem dashed rgb(225, 225, 225);
`;

export const CollapseButton = styled.button<{ collapsed: boolean }>`
  border-radius: 100%;
  border: 0.125rem dashed rgb(225, 225, 225);
  color: rgb(225, 225, 225);
  background-color: #fff;
  position: absolute;
  z-index: 3;
  transform: translateX(-50%);
  top: 13px;
  font-size: 14px;
  transition: 0.5s;
  ${({ collapsed }) => collapsed && "transform: rotate(180deg);"}
`;
