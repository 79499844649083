import DashboardLayout from "../../../components/DashboardLayout";
import Content from "./content";
import { MOBILE_VIEW } from "../../../variables";
import { useMediaQuery } from "react-responsive";

const Overview = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });

  return (
    <DashboardLayout showNavigation={isMobile ? false : true}>
      <Content />
    </DashboardLayout>
  );
};

export default Overview;
