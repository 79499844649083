import { Caption as CaptionType } from "../../../../../../../types";
import { Dropdown } from "react-bootstrap";
import { getIcon } from "../../../../../../../utils/get-icon";
import {
  AddButton,
  BindingIcon,
  CaptionWrapper,
  DropdownToggle,
  DropdownMenu,
  TimestampInput,
  UpperCol,
  UpperRow,
  DeleteButton,
} from "./styled";
import short from "short-uuid";
import moment from "moment";
import { Title } from "../../../../../../../library/title";
import { Text } from "../../../../../../../library/text";
import Button from "../../../../../../../library/button";
import ContentEditable from "react-contenteditable";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { formatTime } from "../../../helpers";

type MobileCaptionProps = {
  captions: any;
  caption: CaptionType;
  onDelete: (key: string) => void;
  onTextChange: (key: string, value: string) => void;
  currentTime: number;
  clickSeek: (time: number) => void;
  setValue: any;
  setIsEditing?: (value: boolean) => void;
  captionRef: any;
};

const MobileCaption: ForwardRefRenderFunction<
  HTMLDivElement,
  MobileCaptionProps
> = ({
  captions,
  caption,
  onDelete,
  onTextChange,
  currentTime,
  clickSeek,
  setValue,
  setIsEditing,
  captionRef,
}) => {
  const start = formatTime(caption?.start);
  const end = formatTime(caption?.end);

  const [duration, setDuration] = useState<{ start: string; end: string }>({
    start: "",
    end: "",
  });

  const convertFloat = (time: string) => {
    const timeMoment = moment(time, "HH:mm:ss,SSS");

    const totalTimeSeconds = timeMoment.diff(
      moment().startOf("day"),
      "seconds"
    );

    const totalTimeFloat = totalTimeSeconds;

    return parseFloat(totalTimeFloat.toFixed(3));
  };

  const renderActive = () => {
    if (!start || !end) return;
    const startTime = convertFloat(start);
    const endTime = convertFloat(end);

    if (currentTime >= startTime && currentTime <= endTime) {
      return true;
    }
    return false;
  };

  const onClick = () => {
    const startTime = convertFloat(start);
    const endTime = convertFloat(end);
    if (currentTime >= startTime && currentTime <= endTime) return;

    const duration = endTime - startTime;
    const percentage = 0.25;
    const offset = duration * percentage;

    const seekTime = startTime + offset;
    clickSeek(seekTime);
  };

  const handleTimestampChange = (e: any, type: string) => {
    const input = e.target.value.replace(/\D/g, "");

    let formattedValue = input;
    if (input.length > 2) {
      formattedValue = `${input.slice(0, 2)}:${input.slice(2)}`;
    }
    if (input.length > 4) {
      formattedValue = `${input.slice(0, 2)}:${input.slice(2, 4)}:${input.slice(
        4
      )}`;
    }
    if (input.length > 6) {
      formattedValue = `${input.slice(0, 2)}:${input.slice(2, 4)}:${input.slice(
        4,
        6
      )}.${input.slice(6, 9)}`;
    }

    setDuration({ ...duration, [type]: formattedValue });
  };

  const convertToFloat = (timestamp: string): number => {
    const [hours, minutes, secondsMilliseconds] = timestamp.split(":");
    const [seconds, milliseconds] = secondsMilliseconds.split(".");

    return (
      parseInt(hours, 10) * 3600 +
      parseInt(minutes, 10) * 60 +
      parseInt(seconds, 10) +
      parseInt(milliseconds, 10) / 1000
    );
  };

  const updateTimestamp = (key: string, type: "start" | "end") => {
    const value = duration[type];
    const isValid = /^\d{2}:\d{2}:\d{2}\.\d{3}$/.test(value);
    if (isValid) {
      const floatValue = convertToFloat(value);
      setValue(
        "captions",
        captions.map((caption: any) => {
          if (caption.key === key) {
            return { ...caption, [type]: floatValue };
          }
          return caption;
        })
      );
    } else {
      setDuration({ ...duration, [type]: type === "start" ? start : end });
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    key: string,
    type: "start" | "end"
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      updateTimestamp(key, type);
    }
  };

  useEffect(() => {
    if (start || end) {
      setDuration({ start: start, end: end });
    }
  }, [caption, caption.start, caption.end, start, end]);

  const clickNewCaption = () => {
    const index = captions.findIndex((c: any) => c.key === caption.key);
    if (index !== -1) {
      const newCaptions = [...captions];
      const currentDuration = newCaptions[index].end - newCaptions[index].start;
      const currentChange = currentDuration * 0.2;

      newCaptions[index] = {
        ...caption,
        end: (caption.end as any) - currentChange,
      };

      if (index + 1 < newCaptions.length) {
        const currentDuration =
          newCaptions[index + 1].end - newCaptions[index + 1].start;
        const currentChange = currentDuration * 0.2;

        newCaptions[index + 1] = {
          ...newCaptions[index + 1],
          start: newCaptions[index + 1].start + currentChange,
        };
      }

      const newCaption: CaptionType = {
        key: short.generate(),
        start: newCaptions[index].end + currentChange * 0.25,
        end:
          index + 1 < newCaptions.length
            ? newCaptions[index + 1].start - currentChange * 0.25
            : newCaptions[index].end + 1,
        text: "",
      };

      newCaptions.splice(index + 1, 0, newCaption);

      setValue("captions", newCaptions);
    }
  };

  const handleOnFocus = () => {
    setIsEditing && setIsEditing(true);
  };

  const handleOnBlur = () => {
    setIsEditing && setIsEditing(false);
  };

  const onContentChange = useCallback(
    (evt: any) => {
      onTextChange(caption?.key, evt.target.value);
    },
    [caption?.key, onTextChange]
  );

  return (
    <CaptionWrapper
      ref={renderActive() ? captionRef : null}
      onClick={onClick}
      className={renderActive() ? "active" : ""}
    >
      <UpperRow>
        <UpperCol className="timestamp left-aligned my-auto">
          <TimestampInput
            type="text"
            placeholder="HH:MM:SS.MS"
            id="timestamp"
            maxLength={12}
            value={duration.start}
            onChange={(e) => handleTimestampChange(e, "start")}
            onKeyDown={(e) => handleKeyDown(e, caption.key, "start")}
            onBlur={() => updateTimestamp(caption.key, "start")}
            pattern="\d{2}:\d{2}:\d{2}\.\d{3}"
          />

          <BindingIcon>{getIcon("arrow-right")}</BindingIcon>
          <TimestampInput
            type="text"
            placeholder="HH:MM:SS.MS"
            id="timestamp"
            value={duration.end}
            maxLength={12}
            onChange={(e) => handleTimestampChange(e, "end")}
            onKeyDown={(e) => handleKeyDown(e, caption.key, "end")}
            onBlur={() => updateTimestamp(caption.key, "end")}
            pattern="\d{2}:\d{2}:\d{2}\.\d{3}"
          />
        </UpperCol>
        <UpperCol className="delete right-aligned">
          <Dropdown>
            <DropdownToggle>
              <DeleteButton>{getIcon("remove")}</DeleteButton>
            </DropdownToggle>
            <DropdownMenu>
              <Title size="xs">Slette tekst?</Title>
              <Text size="small" color="dark">
                Dersom du sletter denne teksten vil du ikke kunne gjenopprette
                den.
              </Text>
              <Button
                onClick={() => onDelete(caption?.key)}
                size="md"
                color="red"
                variant="regular"
              >
                Ja, slett tekst
              </Button>
            </DropdownMenu>
          </Dropdown>
        </UpperCol>
      </UpperRow>
      <ContentEditable
        onChange={onContentChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        contentEditable
        html={caption?.text}
        className={`caption-text ${caption?.text ? "" : "placeholder"}`}
      />
      <AddButton
        onClick={clickNewCaption}
        className={renderActive() ? "active" : ""}
      >
        {getIcon("plus")}
      </AddButton>
    </CaptionWrapper>
  );
};

export default forwardRef(MobileCaption);
