import styled from "@emotion/styled";

export const TopWrapper = styled.div`
  margin: 1.875rem;
  @media (max-width: 975px) {
    margin-left: 0rem;
    margin-right: 0rem;
  }
`;

export const UpperTopWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const LastSavedLabel = styled.span`
  font-family: Poppins, sans-serif;
  color: grey;
  font-size: 14px;
  margin-left: 20px;
  i {
    margin-right: 5px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: inline-flex !important;
  button {
    margin-left: 0.9375rem;
  }
`;

export const RevisionWrapper = styled.div`
  margin-left: 15px;
`;
