import styled from "@emotion/styled";

export const TimelineContainer = styled.div`
  max-height: calc(100vh - 89.94px - 95px - 80px - 70px);
  width: 100%;
  overflow-y: scroll;
  padding-top: 25px;

  @media (max-width: 975px) {
    overflow-y: scroll;
    overflow-y: unset;
    padding-bottom: 15px;
    max-width: unset;
  }
`;
