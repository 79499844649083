import styled from "@emotion/styled";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

export const ContentWrapper = styled.div`
  position: relative;
  padding: 1.875rem;
  padding-top: 0rem;
  overflow-x: hidden;
  display: flex;
  position: relative;
  @media (max-width: 975px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const PanelWrapper = styled(Panel)``;

export const PanelGroupWrapper = styled(PanelGroup)``;

export const PanelResizeHandleWrapper = styled(PanelResizeHandle)`
  width: 2px;
  min-height: 100%;
  background-color: rgb(225, 225, 225);
  margin: 0px 20px;
  opacity: 0.2;
  &:hover {
    opacity: 0.75;
  }
`;

export const PanelGrip = styled.span`
  font-size: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: -4px;
  color: grey;
`;
