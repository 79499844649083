import styled from "@emotion/styled";

export const CaptionWrapper = styled.div`
  position: relative;
  background-color: #fff;
  border-bottom: 0.125rem dashed rgb(225, 225, 225);
  border-radius: 0px;
  padding: 5px 0px;
  padding-bottom: 5px;
  padding: 10px 7.5px;
  transition: 0.25s;
  width: 100%;
  &.active {
    background-color: rgb(229, 240, 249);
  }
  .caption-text {
    width: 100%;
    border: none;
    font-family: Poppins, sans-serif;
    color: grey;
    background: none;
    margin-top: 5px;
    transition: 0.25s;
    font-size: 14px;
    display: block;
    padding: 0px 7.5px;
    &:focus {
      outline: none !important;
      border: 2px solid #2f8de4;
      border-radius: 0.5rem;
      box-shadow: none;
      padding: 5px 10px;
    }
  }
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 975px) {
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

export const Text = styled.span`
  display: block;
  font-family: Poppins, sans-serif;
  color: grey;
  font-size: 14px;
`;

export const UpperContainer = styled.div``;

export const TimeDisplay = styled.span`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
`;

export const BindingIcon = styled.span`
  display: inline;
  margin-left: 0px;
  margin-right: 15px;
  color: grey;
`;

export const AddedText = styled.span`
  background-color: #dfffea;
  border-bottom: 2px solid #17c653;
`;

export const RemovedText = styled.span`
  background-color: #ffeef3;
  border-bottom: 2px solid #f8285b;
`;
