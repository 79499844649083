import { useMediaQuery } from "react-responsive";
import { MOBILE_VIEW } from "../../../../../variables";
import {
  ContentWrapper,
  PanelGroupWrapper,
  PanelWrapper,
  PanelResizeHandleWrapper,
  PanelGrip,
} from "./styled";
import { getIcon } from "../../../../../utils/get-icon";
import VideoPlayer from "../video-player";
import Selections from "../selections";
import Slider from "../slider";
import { useState } from "react";
import Comparison from "../comparison";
import { Font } from "../../../../../types";

interface EditorDisplayProps {
  transcript: any;
  captions: any[];
  currentTime: any;
  videoRef: any;
  appearance: any;
  playerWidth: any;
  setCurrentTime: any;
  setValue: any;
  fonts: Font[];
  register: any;
  playerHeight: number;
  activeCaptionRef: any;
  scrollingContainerRef: any;
  setDataLoaded: any;
  dataLoaded: any;
  setShowError: any;
  activeKey: any;
  setActiveKey: any;
}

const EditorDisplay = ({
  transcript,
  captions,
  currentTime,
  videoRef,
  appearance,
  playerWidth,
  setCurrentTime,
  setValue,
  fonts,
  register,
  playerHeight,
  activeCaptionRef,
  scrollingContainerRef,
  setDataLoaded,
  dataLoaded,
  setShowError,
  activeKey,
  setActiveKey,
}: EditorDisplayProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });
  const [isRevisioning, setIsRevisioning] = useState(false);

  return (
    <div>
      {isMobile ? (
        <>
          <VideoPlayer
            videoUrl={transcript?.videoUrl}
            captions={captions}
            currentTime={currentTime}
            videoRef={videoRef}
            configuration={appearance}
            playerWidth={playerWidth}
            setCurrentTime={setCurrentTime}
          />
          <Selections
            currentTime={currentTime}
            setValue={setValue}
            fonts={fonts}
            register={register}
            appearance={appearance}
            videoRef={videoRef}
            captions={captions}
            playerHeight={playerHeight}
            activeCaptionRef={activeCaptionRef}
            scrollingContainerRef={scrollingContainerRef}
            revisions={transcript?.revisions}
            setIsRevisioning={setIsRevisioning}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
          />
        </>
      ) : (
        <ContentWrapper>
          <PanelGroupWrapper direction="horizontal" id="group">
            <PanelWrapper
              minSize={22.5}
              maxSize={35}
              defaultSize={35}
              id="left-panel"
            >
              <Selections
                currentTime={currentTime}
                setValue={setValue}
                fonts={fonts}
                register={register}
                appearance={appearance}
                videoRef={videoRef}
                captions={captions}
                playerHeight={playerHeight}
                activeCaptionRef={activeCaptionRef}
                scrollingContainerRef={scrollingContainerRef}
                revisions={transcript?.revisions}
                setIsRevisioning={setIsRevisioning}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
              />
            </PanelWrapper>
            <PanelResizeHandleWrapper id="resize-handle">
              <PanelGrip>{getIcon("grip")}</PanelGrip>
            </PanelResizeHandleWrapper>
            <PanelWrapper id="right-panel" maxSize={75}>
              {isRevisioning ? (
                <Comparison
                  videoUrl={transcript?.videoUrl}
                  original={{ captions, appearance }}
                />
              ) : (
                <VideoPlayer
                  videoUrl={transcript?.videoUrl}
                  captions={captions}
                  currentTime={currentTime}
                  videoRef={videoRef}
                  configuration={appearance}
                  playerWidth={playerWidth}
                  setCurrentTime={setCurrentTime}
                />
              )}
            </PanelWrapper>
          </PanelGroupWrapper>
        </ContentWrapper>
      )}
      <Slider
        audioUrl={transcript?.audioUrl}
        audioUrlMobile={transcript?.audioUrlMobile}
        videoRef={videoRef}
        currentTime={currentTime}
        duration={videoRef?.current?.duration}
        setDataLoaded={setDataLoaded}
        dataLoaded={dataLoaded}
        setShowError={setShowError}
      />
    </div>
  );
};

export default EditorDisplay;
