import styled from "@emotion/styled";
import toggleArrow from "../../../../../assets/img/toggle-arrow.svg";
import { Accordion, Col, Row } from "react-bootstrap";

export const AppearanceWrapper = styled.div`
  overflow-x: hidden;
  padding: 0px 10px;
  overflow-y: visible;
  min-height: calc(100svh - 100px - 95px - 120px);
  padding-bottom: 40px;
  @media (max-width: 975px) {
    padding-top: 30px;
    padding-bottom: 0px;
  }
`;

export const AccordionWrapper = styled.div``;

export const AppearanceAccordion = styled(Accordion)``;

export const AccordionItem = styled(Accordion.Item)`
  border: none;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  margin: 0rem !important;
  background: transparent;
  border-bottom: 0.0625rem solid #3b3b3b;
  &:last-of-type {
    border-bottom: none;
  }
  &:not(.collapsed) {
    border-color: #dee2e6 !important;
  }
`;

export const AccordionHeader = styled(Accordion.Header)`
  background: transparent;

  button {
    padding: 1.25rem 0rem;
    background: transparent !important;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    outline: none !important;
    color: grey;
    box-shadow: none !important;
    &:not(.collapsed) {
      color: grey !important;
    }
    &::after {
      content: "";
      display: inline-block;
      width: 17px; /* Adjust size */
      height: 17px; /* Adjust size */
      background-image: url(${toggleArrow}) !important; /* Use the imported icon here */
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: 0; /* Position the icon to the right */
    }
  }
`;

export const InnerAccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AccordionTitle = styled.span`
  color: #3b3b3b;
`;

export const AccordionIcon = styled.span``;

export const AccordionBody = styled(Accordion.Body)`
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
`;

export const Field = styled.div<{ isMobile: boolean }>`
  margin-bottom: 0.9375rem;
  input,
  textarea {
    width: 100%;
    margin-top: 0.3125rem;
    &:focus {
      ${({ isMobile }) =>
        isMobile
          ? ``
          : `animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s;`}
    }
  }

  &:last-of-type {
    margin-bottom: 0rem;
  }
  .toggle-buttons {
    margin-top: 0.3125rem;
    background-color: #f4f4f4;
    height: 49px;
    border-radius: 0.875rem;
    & > div {
      border: none !important;
    }
  }

  .sketch-picker {
    border-radius: 10px !important;
    overflow: hidden;
    margin-top: 0.3125rem;
    min-width: 100%;
    box-shadow: none !important;
    padding: 0px !important;
    margin-bottom: 80px;
    .flexbox-fix {
      background-color: #f4f4f4;
      padding: 10px 20px !important;
      &:last-of-type {
        padding-left: 30px !important;
        padding-right: 30px !important;
        padding-top: 15px !important;
        padding-bottom: 5px !important;
      }
    }
    & > div {
      padding-bottom: 200px !important;
      & > div {
        max-height: 200px !important;
      }
    }
  }
  @keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const InputRow = styled(Row)`
  row-gap: 0.9375rem;
`;

export const InputCol = styled(Col)`
  a {
    font-family: Poppins, sans-serif;
    font-size: 14px;
  }
  & > div > div {
    width: 100%;
  }
`;
