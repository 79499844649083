import { useRef } from "react";
import {
  AccordionWrapper,
  AppearanceAccordion,
  AppearanceWrapper,
} from "./styled";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { VideoAppearanceSchema } from "../schema";
import { useMediaQuery } from "react-responsive";
import { MOBILE_VIEW } from "../../../../../variables";
import PositionSettings from "./position-settings";
import TextSettings from "./text-settings";
import BackgroundSettings from "./background-settings";

type AppearanceProps = {
  fonts: any;
  register: UseFormRegister<VideoAppearanceSchema>;
  appearance: any;
  setValue: UseFormSetValue<VideoAppearanceSchema>;
  updateGoogleFontsLink: any;
};

const Index = ({
  fonts,
  register,
  appearance,
  setValue,
  updateGoogleFontsLink,
}: AppearanceProps) => {
  const appearanceWrapperRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });

  return (
    <AppearanceWrapper ref={appearanceWrapperRef}>
      <AccordionWrapper>
        <AppearanceAccordion defaultActiveKey={["0", "1", "2"]}>
          <PositionSettings
            keyRef="0"
            isMobile={isMobile}
            setValue={setValue}
            appearance={appearance}
          />
        </AppearanceAccordion>
        <AppearanceAccordion defaultActiveKey="1">
          <TextSettings
            isMobile={isMobile}
            register={register}
            appearance={appearance}
            setValue={setValue}
            fonts={fonts}
            keyRef="1"
            updateGoogleFontsLink={updateGoogleFontsLink}
          />
        </AppearanceAccordion>
        <AppearanceAccordion defaultActiveKey="2">
          <BackgroundSettings
            isMobile={isMobile}
            appearance={appearance}
            keyRef="2"
            setValue={setValue}
          />
        </AppearanceAccordion>
      </AccordionWrapper>
    </AppearanceWrapper>
  );
};

export default Index;
