import { RefObject, useEffect, useRef, useState } from "react";
import {
  PlayerWrapper,
  VideoWrapper,
  Video,
  SubtitleWrapper,
  ActiveSubtitle,
  BadgeWrapper,
} from "./styled";
import { Badge } from "../../../../../../library/selections/format-selection/styled";

interface VideoProps {
  videoRef: RefObject<HTMLVideoElement>;
  videoUrl: string;
  configuration: any;
  displayText: string;
  variant: "original" | "comparison";
  collapsed: boolean;
}

const VideoView = ({
  videoRef,
  videoUrl,
  configuration,
  displayText,
  variant,
  collapsed,
}: VideoProps) => {
  const [currentHeight, setCurrentHeight] = useState<number | undefined>(
    undefined
  );
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [dynamicFontSize, setDynamicFontSize] = useState(
    configuration?.fontSize
  );
  const [playerWidth, setPlayerWidth] = useState<number>(0);

  useEffect(() => {
    const updatePlayerHeight = () => {
      if (videoRef.current) {
        const videoElement = videoRef.current;

        setCurrentHeight(videoElement.clientHeight);
        setPlayerWidth(videoElement.clientWidth);
      }
    };

    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener("loadedmetadata", updatePlayerHeight);
      window.addEventListener("resize", updatePlayerHeight);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadedmetadata", updatePlayerHeight);
        window.removeEventListener("resize", updatePlayerHeight);
      }
    };
  }, [videoRef]);

  useEffect(() => {
    const updateFontSize = () => {
      if (videoRef.current) {
        const videoHeight = videoRef.current.clientHeight;
        const userDefinedFontSize = configuration?.fontSize || 16;

        const scaleFactor = videoHeight / 500;
        const calculatedFontSize = userDefinedFontSize * scaleFactor;

        setDynamicFontSize(calculatedFontSize);
      }
    };

    const handleMetadataLoaded = () => {
      updateFontSize();
    };

    if (videoRef.current) {
      videoRef.current.addEventListener("loadedmetadata", handleMetadataLoaded);
    }

    window.addEventListener("resize", updateFontSize);

    updateFontSize();

    return () => {
      if (videoRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        videoRef.current.removeEventListener(
          "loadedmetadata",
          handleMetadataLoaded
        );
      }
      window.removeEventListener("resize", updateFontSize);
    };
  }, [videoRef, configuration?.fontSize]);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      const fullHeight = wrapper.scrollHeight;
      setCurrentHeight(fullHeight);
    }
  }, [collapsed]);

  return (
    <PlayerWrapper
      ref={wrapperRef}
      currentHeight={currentHeight}
      collapsed={collapsed}
    >
      <BadgeWrapper>
        <Badge>
          {variant === "original" ? "Siste versjon" : "Valgt versjon"}
        </Badge>
      </BadgeWrapper>

      <VideoWrapper>
        <Video
          src={videoUrl}
          preload="metadata"
          playsInline
          controls={false}
          ref={videoRef}
        >
          Your browser does not support the video tag.
        </Video>
        <SubtitleWrapper
          className={`vertical-${configuration?.alignment?.vertical}`}
          style={{
            textAlign: configuration?.alignment?.horizontal || "center",
          }}
          playerWidth={playerWidth}
        >
          <ActiveSubtitle
            fontSize={dynamicFontSize}
            color={configuration?.color}
            backgroundColor={configuration?.backgroundColor}
            fontFamily={configuration?.fontFamily}
            decoration={configuration?.decoration}
            fontStyle={configuration?.fontStyle}
          >
            {displayText}
          </ActiveSubtitle>
        </SubtitleWrapper>
      </VideoWrapper>
    </PlayerWrapper>
  );
};

export default VideoView;
