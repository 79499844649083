import Timeline from "../../timeline";
import Appearance from "../../appearance";
import Revisions from "../../revisions";
import Tabs from "../../../../../../library/tabs";

interface DesktopViewProps {
  currentTime: number;
  videoRef: any;
  clickDelete: any;
  handleTextChange: any;
  captions?: any[];
  setValue: any;
  appearance: any;
  fonts: any;
  register: any;
  updateGoogleFontsLink: any;
  scrollingContainerRef: any;
  activeCaptionRef: any;
  revisions: any[];
  setIsRevisioning: any;
  activeKey: any;
  setActiveKey: any;
}

const DesktopView = ({
  currentTime,
  videoRef,
  clickDelete,
  handleTextChange,
  captions,
  setValue,
  appearance,
  fonts,
  register,
  updateGoogleFontsLink,
  scrollingContainerRef,
  activeCaptionRef,
  revisions,
  setIsRevisioning,
  activeKey,
  setActiveKey,
}: DesktopViewProps) => {
  const SELECTIONS = [
    {
      id: 1,
      key: "timeline",
      title: "Undertekster",
      display: (
        <Timeline
          currentTime={currentTime}
          videoRef={videoRef}
          onDelete={clickDelete}
          onTextChange={handleTextChange}
          captions={captions}
          setValue={setValue}
          scrollingContainerRef={scrollingContainerRef}
          activeCaptionRef={activeCaptionRef}
        />
      ),
    },
    {
      id: 1,
      key: "appearance",
      title: "Endre utseende",
      display: (
        <Appearance
          appearance={appearance}
          fonts={fonts}
          register={register}
          setValue={setValue}
          updateGoogleFontsLink={updateGoogleFontsLink}
        />
      ),
    },
    {
      id: 2,
      key: "revisions",
      title: "Revisjonshistorikk",
      display: (
        <Revisions revisions={revisions} setIsRevisioning={setIsRevisioning} />
      ),
    },
  ];

  return (
    <Tabs
      activeTab={activeKey}
      setActiveTab={setActiveKey}
      selections={SELECTIONS}
    />
  );
};

export default DesktopView;
