import ReactTimeAgo from "react-time-ago";
import Breadcrumb from "../../../../../../library/breadcrumb";
import {
  ButtonsWrapper,
  LastSavedLabel,
  RevisionWrapper,
  TopWrapper,
  UpperTopWrapper,
} from "./styled";
import ExportDetails from "../../../../../../components/ExportModal";
import { getIcon } from "../../../../../../utils/get-icon";
import Button from "../../../../../../library/button";
import Modal from "../../../../../../library/modal";
import { Dispatch, SetStateAction, useState } from "react";
import useTranscript from "../../../../../../hooks/useTranscript";
import { VideoAppearance, VideoCaption } from "../../../../../../types";
import { setActiveTranscript } from "../../../../../../redux/slices/transcript";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import DashboardTopBar from "../../../../../../components/DashboardTopBar";

interface DesktopTopBarProps {
  onGoBack: () => void;
  onSave: () => void;
  onGenerateVideo: () => void;
  generateLoading: boolean;
  progress: number;
  lastUpdated?: number;
  transcriptId?: string;
  title?: string;
  captions: VideoCaption[];
  appearance: VideoAppearance;
  videoDownloadUrl?: string;
  setVideoDownloadUrl?: Dispatch<SetStateAction<string | undefined>>;
  setActiveKey: (key: string) => void;
}

const DesktopTopBar = ({
  onGoBack,
  onSave,
  onGenerateVideo,
  generateLoading,
  lastUpdated,
  progress,
  title,
  transcriptId,
  captions,
  appearance,
  videoDownloadUrl,
  setVideoDownloadUrl,
  setActiveKey,
}: DesktopTopBarProps) => {
  const dispatch = useAppDispatch();
  const { transcript, saveLoading } = useTranscript();
  const comparison = useAppSelector((state) => state.transcript.comparison);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const openExportModal = () => {
    setShowExportModal(true);
  };

  const closeExportModal = () => {
    setShowExportModal(false);
    if (videoDownloadUrl) {
      setVideoDownloadUrl && setVideoDownloadUrl(undefined);
    }
  };

  const onExport = () => {
    openExportModal();
  };

  const clickRevise = () => {
    setActiveKey("timeline");
    dispatch(
      setActiveTranscript({
        ...transcript,
        content: comparison.content,
      })
    );
  };

  return (
    <>
      <TopWrapper>
        <UpperTopWrapper>
          <Breadcrumb title="Gå tilbake" onClick={onGoBack} />
          <LastSavedLabel>
            {saveLoading ? getIcon("spinner") : getIcon("cloud")}
            Lagret{" "}
            {lastUpdated && (
              <ReactTimeAgo locale="nb" date={new Date(lastUpdated * 1000)} />
            )}
          </LastSavedLabel>
        </UpperTopWrapper>
        <DashboardTopBar
          title={title || "Ikke tilgjengelig"}
          buttons={
            <ButtonsWrapper>
              <Button
                onClick={onSave}
                icon="save"
                variant="regular"
                color="grey"
              >
                {saveLoading ? "Lagrer.." : "Lagre"}
              </Button>
              <Button
                onClick={onExport}
                icon="download"
                variant="regular"
                color="blue"
              >
                Last ned
              </Button>
              {comparison && (
                <RevisionWrapper>
                  <Button
                    onClick={clickRevise}
                    icon="revert"
                    variant="regular"
                    color="blue"
                  >
                    Hent tilbake
                  </Button>
                </RevisionWrapper>
              )}
            </ButtonsWrapper>
          }
        />
      </TopWrapper>
      <Modal open={showExportModal} onClose={closeExportModal} title="Last ned">
        <ExportDetails
          onClose={closeExportModal}
          transcriptId={transcriptId ?? ""}
          captions={captions}
          configuration={appearance}
          onGenerateVideo={onGenerateVideo}
          generateLoading={generateLoading}
          variant="editor"
          progress={progress}
          videoDownloadUrl={videoDownloadUrl}
        />
      </Modal>
    </>
  );
};

export default DesktopTopBar;
